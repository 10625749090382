import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './regForm.module.css';
import { Validator } from '../../features/helpers/validator';
import { setError, clearError } from './regformSlice';
import { countryList } from '../../data';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker,
} from '@material-ui/pickers';

const accountType = [
    "Savings", "Current", "Mortgage", "Equity", "Checkings",
    "Fixed Deposit", "IRA Application", "SB Account"
]

const Biography = ({state, dispatch}) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [country, setCountry] = useState('');
    const [dob, setDob] = useState(new Date());
    const [language, setLanguage] = useState('');
    const [accounttype, setAccounttype] = useState('');

    const inputChange = (e, setter) => {
        setter(e.target.value);
    }

    const handleDate = (date) => {
        //Because angular material date component doesn't
        //return an event dump, we create our own
        const e = {
            target: {
                name: 'dob'
            }
        }
        let formatDob = date.toString();
        setDob(formatDob);
        validate(e, dob, "dob");
    }

    const validate = (e, input, inputString) => {
        const result = Validator(e, input, inputString);
        
        if(result.error){
            dispatch(setError({
                input: result.input,
                err: result.errorMessage
            }));
        } else {
            dispatch(clearError({
                input: result.input,
                val: result.inputValue
            }))
        }
    }

    return (
        <section className={styles.cover}>
            <div className={styles.headerInfo}>
                <Typography variant="h6" color="primary"> Personal Details</Typography>
                <Typography variant="body2" color="primary">
                    Let's start by getting your personal details
                </Typography>
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="name"> Full Name </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="name"
                    onChange={(e) => inputChange(e, setName)}
                    required
                    onBlur={(e) => validate(e, name, "name")}
                    error={state.name !== ''}
                    helperText={
                        (state.name) ? state.name : ""
                    }
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="email"> Email address </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="email"
                    onChange={(e) => inputChange(e, setEmail)}
                    required
                    onBlur={(e) => validate(e, email, "email")}
                    error={state.email !== ''}
                    helperText={
                        (state.email) ? state.email : ""
                    }
                />
            </div>
                
            <div className={styles.formGroup}>
                <label htmlFor="number"> Tel. Number </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    type="Number"
                    id="number"
                    onChange={(e) => inputChange(e, setNumber)}
                    required
                    onBlur={(e) => validate(e, number, "number")}
                    error={state.number !== ''}
                    helperText={
                        (state.number) ? state.number : ""
                    }
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="street"> Street </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="street"
                    onChange={(e) => inputChange(e, setStreet)}
                    required
                    onBlur={(e) => validate(e, street, "street")}
                    error={state.street !== ''}
                    helperText={
                        (state.street) ? state.street : ""
                    }
                />
            </div>

            <Grid container>
                <Grid item container xs={12} sm={5} md={4} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <div className={styles.formGroup}>
                            <label htmlFor="city"> city </label>
                            <TextField 
                                size="small" 
                                variant="outlined"
                                id="city"
                                onChange={(e) => inputChange(e, setCity)}
                                required
                                onBlur={(e) => validate(e, city, "city")}
                                error={state.city !== ''}
                                helperText={
                                    (state.city) ? state.city : ""
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={styles.formGroup}>
                            <label htmlFor="region"> State </label>
                            <TextField 
                                size="small" 
                                variant="outlined"
                                id="region"
                                onChange={(e) => inputChange(e, setRegion)}
                                required
                                onBlur={(e) => validate(e, region, "region")}
                                error={state.region !== ''}
                                helperText={
                                    (state.region) ? state.region : ""
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <div className={styles.formGroup}>
                <label htmlFor="country"> Country </label>                                
                <TextField
                    id="country"
                    name="country"
                    select
                    value={country}
                    onChange={(e) => inputChange(e, setCountry)}
                    onBlur={(e) => validate(e, country, "country")}
                    error={state.country !== ''}
                    helperText={
                        (state.country) ? state.country : ""
                    }
                    >                                
                    {countryList.map((cnt) => (
                        <MenuItem key={cnt.name} value={cnt.name}>
                            {cnt.name} ({cnt.code})
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="dob">
                    Please click the date icon, Do not type in the date
                </label> 
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="dob"
                        name="dob"
                        label="Date of birth"
                        value={dob}
                        error={state.dob !== ''}
                        helperText={
                            (state.dob) ? state.dob : ""
                        }
                        onChange={(date) => handleDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
            
            <div className={styles.formGroup}>
                <label htmlFor="language"> Language </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="language"
                    onChange={(e) => inputChange(e, setLanguage)}
                    required
                    onBlur={(e) => validate(e, language, "language")}
                    error={state.language !== ''}
                    helperText={
                        (state.language) ? state.language : ""
                    }
                />
            </div>


            <div className={styles.formGroup}>
                <label htmlFor="accounttype"> Account Type </label>                                
                <TextField
                    id="accounttype"
                    name="accounttype"
                    select
                    value={accounttype}
                    onChange={(e) => inputChange(e, setAccounttype)}
                    onBlur={(e) => validate(e, accounttype, "accounttype")}
                    error={state.accounttype !== ''}
                    helperText={
                        (state.accounttype) ? state.accounttype : ""
                    }
                    >                                
                    {accountType.map((a_t, i) => (
                        <MenuItem key={i} value={a_t}>
                            {a_t}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            

        </section>
    )
}

export default Biography;