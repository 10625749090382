import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import ImageText from '../../features/image-text/ImageText';
import { switchData, listWithCheckData } from '../../data';
import ListWithCheck from '../../features/list-with-check/ListWithCheck';
import { giftIcon, guaranteeImg, fscsImg } from '../../images';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';


const PPT1 = () => <ListWithCheck listData={listWithCheckData[1]} />

const PPT2 = () => {
  return (
    <div>
      <h1 style={{fontSize: '2.2em'}}> Switch to Royal Bank </h1>

      <p>
        The Current Account Switch Service will do all the work when it comes to
        switching, moving everything across from your old account all within 7
        working days, including Direct Debits and standing orders. All you need
        to do is tell us the details of your old bank account and when you want
        the Switch to start.
      </p>
    </div>
  )
}

const PPT3 = () => {
  return (
    <section>
      <h2> Financial Services Compensation Scheme </h2>

      <p>
        Your eligible deposits with the Royal Bank of America are protected up
        to a total of $85,000 by the Financial Services Compensation Scheme, the
        UK's deposit guarantee scheme.
      </p>
      <br /><br /><br />
    </section>
  )
}

const PremierProducts = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTop
        header="What is Premier Banking?"
        buttonText="What is Premier Banking?"
        buttonLink="/current-account"
        bgImage = "premierProducts"
        displayBtn = {false}
       />

      <ImageText
        PageText={PPT1}
        image={giftIcon}
        floatImageRight={true}
       />

      <ContainerMaxWidth>
        <h2> How do you qualify for Premier Banking? </h2>

        <p> You'll need to be a permanent UK resident and meet one of the following: </p>

        <ThreeImageCard cardData={switchData[6]} />

      </ContainerMaxWidth>

      <section style={{background: '#280020', color: 'white'}}>
        <ImageText
          PageText={PPT2}
          image={guaranteeImg}
          floatImageRight={true}
         />
      </section>

      <ThreeImageCard cardData={switchData[7]} />

      <ImageText
        PageText={PPT3}
        image={fscsImg}
       />

    </section>
  )
}

export default PremierProducts;
