import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTopSolid from '../../features/page-top-solid/PageTopSolid';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import { listWithTopBorder } from '../../data';
import ListComponent from '../../features/list-component/ListComponent';
import { Grid } from '@material-ui/core';

const BusinessLoans = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTopSolid
        header="Business Loans and Finances"
        text="Discover the right business funding for you."
        />

      <ContainerMaxWidth>
        <Grid container spacing={6}>
          <Grid item xs={12} md={8}>
            <h1 style={{fontSize: '2.4em', color: '#002D64'}}> Everyday business finanance </h1>

            <p>
              We provide enterprise owners with flexible, everyday business
              finance options. Whether you're after instant access to cash with a
              business credit card for day-to-day expenses, or a small business loan to
              fund a new project with, we can help you achieve your goals. Below you can
              compare our business loans available to our customers.
            </p>
            <br />
            <p>
              Security and / or Director’s guarantee may be required. Product fees may apply.
              To apply you must be a UK resident aged 18 or over. Business credit cards are
              for eligible UK businesses only and you must maintain a business bank account
              in the same legal entity name with Royal Bank of America. Subject to status,
              business use only.
            </p>
          </Grid>

          {
            listWithTopBorder[1].map((ebf, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListComponent listData={listWithTopBorder[1][index]} />
              </Grid>
            ))
          }

          <Grid item xs={12} md={8}>
            <h1 style={{fontSize: '2.4em', color: '#002D64'}}> Large business loan </h1>

            <p>
              Whether you’ve found the perfect premises, or you’re looking to borrow towards
              a long-term project, we have several business finance options for large amounts.
              You can choose from our fixed or variable business loan products, our
              commercial mortgage product - which comes with a choice of fixed or variable
              repayments or one of our Real Estate finance products for your next Real Estate
              project. Explore our products below.
            </p>
            <br />
            <p>
              Security may be required. Product fees may apply. Over 18s only. Subject to
              status, business use only. Any property or asset used as security may be
              repossessed or forfeited if you do not keep up repayments on any debt secured
              on it.
            </p>
          </Grid>

          {
            listWithTopBorder[2].map((lbl, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListComponent listData={listWithTopBorder[2][index]} />
              </Grid>
            ))
          }


          <Grid item xs={12} md={8}>
            <h1 style={{fontSize: '2.4em', color: '#002D64'}}> Alternative business funding </h1>

            <p>
              We have a range of alternative business funding options that can help you
              achieve your goals. If you want to borrow money against the value of an
              invoice, we have four types of Invoice Finance. If you need to buy equipment
              and don't want the hassle of ownership, Asset Finance might suite you better.
            </p>
            <br />
            <p>
              <sub>Security may be required. Product fees may apply. Over 18s only. Subject to
              status, business use only. Any property or asset used as security may be
              repossessed or forfeited if you do not keep up repayments on any debt secured
              on it.</sub>
            </p>
          </Grid>

          {
            listWithTopBorder[3].map((abf, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListComponent listData={listWithTopBorder[3][index]} />
              </Grid>
            ))
          }

        </Grid>
      </ContainerMaxWidth>

    </section>
  )
}

export default BusinessLoans
