import React, { useState } from 'react';
import { Grid, Container } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RoomIcon from '@material-ui/icons/Room';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import {NavLink} from 'react-router-dom';

import styles from './footer.module.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const Footer = () => {
  const [show, setShow] = useState(false)

  window.addEventListener("scroll", function(event) {
    var top = this.scrollY
        if(top > 500){
          setShow(true)
        } else {
          setShow(false);
        }
  }, false);

  const scrollToTop = () => {
    window.scroll({
      top: 0, left: 0, behavior: 'smooth'
    })
  }
  

  const personalRoute = "/h/personal";
  const premeierRoute = "/h/premier";
  const businessRoute = "/h/business";

  const footerTop = [
    {
      icon: <HelpOutlineIcon />,
      text: 'Support Centre',
    },
    {
      icon: <RoomIcon />,
      text: 'Find a branch'
    },
    {
      icon: <PhoneAndroidIcon />,
      text: 'Get the Royal bank App'
    }
  ];

  const footerLinks = [
    [
      {
        link: `${personalRoute}`,
        linkText: 'Personal'
      },
      {
        link: `${personalRoute}/bank-accounts`,
        linkText: 'Bank accounts'
      },

      {
        link: `${personalRoute}/support`,
        linkText: 'Support Center'
      },

      {
        link: `${personalRoute}/financial-health`,
        linkText: 'Financial Health'
      },

      {
        link: `${personalRoute}/coronavirus`,
        linkText: 'Coronavirus Support'
      },
      
      {
        link: `/h/contact`,
        linkText: 'Contact Us'
      }
    ],

    [
      {
        link: `${premeierRoute}`,
        linkText: 'Premier'
      },
      {
        link: `${premeierRoute}/premier-products`,
        linkText: 'Premier Product'
      },
      {
        link: `${premeierRoute}/premier-select`,
        linkText: 'Premier Select'
      },
      {
        link: `${premeierRoute}/help-and-support`,
        linkText: 'Help and Support'
      },

    ],

    [
      {
        link: `${businessRoute}`,
        linkText: 'Business'
      },
      {
        link: `${businessRoute}/business-loans`,
        linkText: 'Business Loans and Finance'
      },
      {
        link: `${businessRoute}/running-a-business`,
        linkText: 'Running a business'
      },
      {
        link: `${businessRoute}/business-security`,
        linkText: 'Business Security'
      },
      {
        link: `${businessRoute}/help-and-support`,
        linkText: 'Coronavirus Support'
      },

    ],


  ]

  return(
    <section>
      <Grid container style={{background: '#002D64'}} className={styles.footerContainer}>
        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Grid container>
              {
                footerTop.map((item, index) =>(
                  <Grid key={index} item className={styles.footerTop} xs={12} sm={4}>
                    {item.icon} &nbsp; &nbsp; {item.text}
                  </Grid>
                ))
              }
            </Grid>

            <Grid container>
              {
                footerLinks.map((linkItem, index) => (
                  <Grid key={index} item xs={12} sm={4}>
                    <ul>
                      {
                        linkItem.map((link, index) => (
                          <li key={index}>
                            <NavLink onClick={scrollToTop} to={link.link}>{link.linkText}</NavLink>
                          </li>
                        ))
                      }
                    </ul>
                  </Grid>
                ))
              }
            </Grid>

            <hr />

            <Grid container>
              <Grid className={styles.ep} item xs={6} sm={3}> Privacy & Cookes </Grid>
              <Grid className={styles.ep} item xs={6} sm={3}> Website T&Cs & FSCS </Grid>
              <Grid className={styles.ep} item xs={6} sm={3}> Accessibility </Grid>
              <Grid className={styles.ep} item xs={6} sm={3}> Carreers </Grid>
              <Grid className={styles.ep} item xs={6} sm={3}> Site Map </Grid>
              <Grid className={styles.ep} item xs={6} sm={3}> Modern Slavery Statement </Grid>
            </Grid>

            <Grid container className={styles.socials}>
              <Grid item xs={7} md={10}>
                <span> <FacebookIcon /> </span>
                <span> <TwitterIcon /> </span>
                <span> <RssFeedIcon /> </span>
                <span> <LinkedInIcon /> </span>
              </Grid>


              <Grid item xs={5} md={2}>
                <img src="https://i.ibb.co/HdvZBzq/fscs-logo.png" height="30px" alt="fcsc" />
              </Grid>

              <p>© Royal Bank of America plc 2020</p>

            </Grid>

          </Container>
        </Grid>
      </Grid>

      <div className={styles.scrollUp} 
        style={{ display: show ? 'block' : 'none'}}
        >
        <p className={styles.scrollUpText}> Go back up </p>
        <ArrowUpwardIcon className={styles.scrollIcon} onClick={scrollToTop} />
      </div>
    </section>
  )
}

export default Footer;
