import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import styles from './premierSelect.module.css';
import ImageText from '../../features/image-text/ImageText';
import { switchData, listWithCheckData } from '../../data';
import ListWithCheck from '../../features/list-with-check/ListWithCheck';
import { coffeeAndPhoneImg } from '../../images';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';

const PST = () => <ListWithCheck listData={listWithCheckData[2]} />

const PremierSelect = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTop
        header="Get a Premier Select Account"
        buttonText="What is Premier Banking?"
        bgImage = "premierSelect"
        displayBtn = {false}
       />

       <h2 style={{textAlign: 'center'}}> More than just an everyday bank account </h2>

       <ImageText
         PageText={PST}
         image={coffeeAndPhoneImg}
        />

        <div style={{textAlign: 'center'}}>
          <h2> I already have a Premier Select account </h2>
          <p> I already have a Premier Select account </p>
        </div>


      <ContainerMaxWidth>
        <br /><br /><br />
        <h2> How to Open an account online </h2>

        <ThreeImageCard cardData={switchData[8]} />

      </ContainerMaxWidth>

      <section style={{background: '#280020', color: 'white', paddingBottom: '40px'}}>
        <ContainerMaxWidth>
          <p className={styles.apply}> Get ready to apply </p>
          <p>
            Before you apply, we need to check you're happy with a few things. <br />And
            ask you a few questions up front. Then we can make your application <br /> as
            quick and simple as it can be.
          </p>
        </ContainerMaxWidth>
      </section>

      <ContainerMaxWidth>
        <br /><br /><br />
        <h2> First things first. You can apply if you: </h2>

        <ul>
          <li> are aged 18+ and a UK resident </li>
        </ul>

          <strong> You must also meet one of these criteria: </strong>
        <ul>
          <li> Minimum $100k sole income or $120k joint income paid into your Royal Bank account. </li>

          <li> $500k minimum mortgage borrowing with us. </li>

          <li> Minimum $100k savings or investments held with us. </li>
        </ul>
        <br /><br /><br />
      </ContainerMaxWidth>

    </section>
  )
}

export default PremierSelect
