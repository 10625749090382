import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTopSolid from '../../features/page-top-solid/PageTopSolid';
import ImageText from '../../features/image-text/ImageText';
import { trowelImg, trolleyImg, signPostImg } from '../../images';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import { Grid } from '@material-ui/core';

const RBT1 = () => {
  return (
    <section>
      <h1 style={{fontSize: '2.4em', color: '#002D64'}}> One step at a time </h1>

      <p>
        Consideration and careful planning help no end when growing your business,
        says James Hind, founder of car review aggregator
      </p>

      <p>
        We launched Volkswagen first, sold 400 of them and got that working smoothly
        over a year. Once visitors could use the platform without any signs of a
        glitch, we launched Audi and nearly doubled the volume of sales within a few
        months. We kept this progressive rollout approach going until we had all of
        the brands on the site that we were aiming for.
      </p>

    </section>
  )
}

const RBT2 = () => {
  return (
    <section>
      <h1 style={{fontSize: '2.4em', color: '#002D64'}}>
        Consider your export opportunities
      </h1>

      <p>
        Finding new customers is an obvious way to boost your business but many UK
        companies neglect the opportunities that exporting to markets overseas can
        bring, fearing it to be too complicated, or incredibly expensive.
      </p>

      <p>
        Click the links below to find out more about our trade products:
      </p>

      <ul style={{listStyleType: 'disc', marginLeft: '1.3em'}}>
        <li>Trade loans</li>
        <li>Bonds, Guarantees and Standby Letters of Credit</li>
        <li>Documentary collections</li>
        <li>Letters of credit</li>
      </ul>
    </section>
  )
}

// const RBT3 = () => {
//   return(
//     <section>
//       <h1 style={{fontSize: '2.4em', color: '#002D64'}}>
//         Collaboration
//       </h1>
//
//       <p>
//         With so many businesses out there, the quickest way to grow could be through
//         a simple and informal tie-up or collaboration. Becoming a member of a local
//         networking group can often help establish relationships, helping to gain and
//         give referrals while also hearing about work you could join forces on.
//       </p>
//
//       <p>
//         Royal Bank of America run a number of events throughout the UK, helping
//         business owners grow their network and collaborate under one roof.
//       </p>
//     </section>
//   )
// }

const RBT4 = () => {
  return(
    <section>
      <h1 style={{fontSize: '2.4em', color: '#002D64'}}>
        Entrepreneur Accelerator
      </h1>

      <p>
        Our fully funded accelerator has 12 hubs across the UK. We provide startups
        with unique coaching environments, digital learning and networking
        opportunities.
      </p>
    </section>
  )
}

const RunningBusiness = () => {
    return(
      <section>
        <SiteMap sliceIndex={2} />
        <br />

      <PageTopSolid
        header="Key growth strategies"
        text="for any business"
        />

      <ImageText
        PageText={RBT1}
        image={trowelImg}
        floatImageRight={true}
       />

       <ContainerMaxWidth>
         <Grid container>
          <Grid item>
           <h1 style={{fontSize: '2.4em', color: '#002D64'}}>
             Turn your business into a franchise
           </h1>

           <p>
             You may be interested in Franchising your business where you, as franchisor,
             grant a licence to your franchisees to operate a business under your name.
             You will be giving them a system to market your products or services for a
             specified period, and often in a specified geographical area.
           </p>
           </Grid>
         </Grid>
       </ContainerMaxWidth>

       <ImageText
         PageText={RBT2}
         image={trolleyImg}
        />

      <section style={{background: '#95077D', color: 'white', padding: '20px'}}>
        <ContainerMaxWidth>
          <Grid container>
            <Grid item>
              <h1 style={{fontSize: '2.4em'}}> Talent at the top </h1>

              <p>
                Any business can benefit from experience at boardroom level. In fact,
                according to executive search and interim management firm Intramezzo, not
                having the right board make-up is one major way to hamper growth.
              </p>

              <p>
                Their Talent Capital Report found that the quality and strength of the
                leadership team was a top factor in gaining investment, with 85% of
                respondents ‘unlikely’ or ‘highly unlikely’ to back a company that lacks
                the right skills.
              </p>

              <p>
                A successful talent strategy is dependent on recognising whether the
                existing leadership team is capable of meeting not only current objectives
                but the longer term ambitions of the business. Says Dermot Hill, CEO of
                Intramezzo
              </p>
              <br /><br /><br /><br />
            </Grid>
          </Grid>
        </ContainerMaxWidth>
      </section>

      <ImageText
        PageText={RBT4}
        image={signPostImg}
       />


      </section>
    )
}

export default RunningBusiness;
