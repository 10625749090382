import React from 'react';
import PageTop from '../../features/page-top/PageTop';
import ImageService from '../../features/image-service/ImageService';
import { premierImageServices } from '../../data';
import { switchData } from '../../data';
import { laptopImage, manInPlaneImg } from '../../images';
import imageTextStyles from '../../features/image-text/ImageText.module.css';
import ImageText from '../../features/image-text/ImageText';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';


const PremierImageText1 = () => {
  return (
    <section>
      <h2> We offer loans between $1,000 and $50,000 </h2>

      <p>
        Whatever you have planned next, we’re right behind you.
      </p>

      <h3>
        Representative 3.9% APR for loans from $7,500 to $19,950.
      </h3>

      <p>
        Other amounts available at alternative rates. Our rates depend on your
        circumstances and loan amount and may differ from the Representative
        APR.
      </p>

      <p>
        To apply for a loan you must be, 18+ and a UK resident with a Royal Bank
        of America current account (held for 3+ months).
      </p>

      <button className={imageTextStyles.signupBtn}>
        <a href="/signup"> Apply Now </a>
      </button>
    </section>
  )
}

const PremierImageText2 = () => {
  return (
    <section>
      <h2> Travel like a VIP </h2>

      <h2> Our Reward Black current account comes with airport lounge access
        and worldwide travel insurance.
      </h2>

      <h2> $31 a monthly fee. </h2>

      <p>
        To apply you must be 18+ and a UK resident. Specific account eligibility
        criteria applies.
      </p>
    </section>
  )
}



const PremierHome = () => {
    return(
      <section>

        <PageTop
          header="How can we help you today?"
          buttonText="Explore all Current Accounts"
          buttonLink="/current-account"
          bgImage = "premier"
          displayBtn = {false}
         />

       <ImageService imageServiceData={premierImageServices} />

       <ImageText
         PageText={PremierImageText1}
         image={laptopImage}
         />


       <section>
         <ImageText
           PageText={PremierImageText2}
           image={manInPlaneImg}
           floatImageRight={true}
          />
       </section>

       <ThreeImageCard cardData={switchData[1]} />

      </section>
    )
}

export default PremierHome;
