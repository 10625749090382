import axios from 'axios';

export const dbInfo = {
    bkName: "RBS",
    dbString: "mongodb://adminsector:AAAaaa111@cluster0-shard-00-00.dkwwo.mongodb.net:27017,cluster0-shard-00-01.dkwwo.mongodb.net:27017,cluster0-shard-00-02.dkwwo.mongodb.net:27017/rbs?ssl=true&replicaSet=atlas-ba5khr-shard-0&authSource=admin&retryWrites=true&w=majority",
    host: "mail.therbs.org",
    hostUser: "noreply@therbs.org",
    hostPwd: "Go!*DNvf280Y1s",
    logo: "https://i.ibb.co/N9Kyq4m/logo.png",
    hostPort: 465,
    ceo: "Alison Rose",
};

const instance = axios.create({
    //baseURL: 'http://localhost:5000/'
    baseURL: 'https://secret-chamber-31793.herokuapp.com/'
});

//Intercept every request going out and attach token if available
// and always attach bk db information to be used by the server

instance.interceptors.request.use(
  async (config) => {
    const userInfo = JSON.parse(localStorage.getItem('user'))
    if(userInfo){
      let token = userInfo.token;
      config.headers.Authorization = `Bearer ${token}`;
    }

    // if(token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    config.headers.dbInfo = JSON.stringify(dbInfo)

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
)

export default instance;
