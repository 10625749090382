import React from 'react';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import { Grid } from '@material-ui/core';
import { svgLogo, whiteLogo } from '../../images';
import LockIcon from '@material-ui/icons/Lock';
import styles from './authHeader.module.css';
import { NavLink } from 'react-router-dom';

const AuthHeader = ({ goto }) => {
    return (
        <section style={{background: '#002D64', padding: '15px'}}>
            <ContainerMaxWidth>
                <Grid container>
                    <Grid item xs={7} sm={9}>
                        <NavLink to="/">
                            <img
                                srcSet={svgLogo}
                                src={whiteLogo}
                                alt="Royal Bank of America Logo - Back to home page "
                                style={{borderWidth: "0px"}} />
                            </NavLink>
                    </Grid>

                    <Grid item xs={5} sm={3}>
                        <NavLink to={`/${goto}`} className={styles.loginBtn}>
                            <span> Go to {goto[0].toUpperCase() + goto.substring(1)} </span> &nbsp; &nbsp; <LockIcon />
                        </NavLink>
                    </Grid>
                </Grid>
            </ContainerMaxWidth>
        </section>
    )
}

export default AuthHeader;